import {
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as actions from '../actions/ChatActions';
import { getMembersRequest } from '../actions/ChatActions';
import history from '../history';
import ChatRepository from '../repositories/ChatRepository';
import { store } from '../store';

function* getChatChannels() {
  try {
    const result = yield call(ChatRepository.getAllChatThreads);
    yield put({
      type: actions.Types.GET_CHAT_CHANNELS_SUCCESS,
      payload: result.threads,
    });
  } catch (errors) {
    yield put({
      type: actions.Types.GET_CHAT_CHANNELS_FAILURE,
      payload: errors,
    });
  }
}

function* watchGetChatChannelsRequest() {
  yield takeEvery(
    actions.Types.GET_CHAT_CHANNELS_REQUEST,
    getChatChannels
  );
}

function* getChatChannelMembers(action) {
  try {
    const result = yield call(
      ChatRepository.getMembersChatChannel,
      action.payload
    );
    yield put({
      type: actions.Types.GET_CHAT_MEMBERS_SUCCESS,
      payload: result.users,
    });
  } catch (errors) {
    yield put({
      type: actions.Types.GET_CHAT_MEMBERS_FAILURE,
      payload: errors,
    });
  }
}

function* watchGetChatChannelMembersRequest() {
  yield takeEvery(
    actions.Types.GET_CHAT_MEMBERS,
    getChatChannelMembers
  );
}

function* getChatThreadRequest(action) {
  try {
    const result = yield call(
      ChatRepository.getChatThreadRequest,
      action.payload
    );
    yield put({
      type: actions.Types.GET_CHAT_THREAD_SUCCESS,
      payload: result.thread,
    });
  } catch (errors) {
    yield put({
      type: actions.Types.GET_CHAT_THREAD_FAILURE,
      payload: errors,
    });
  }
}

function* watchGetChatThreadRequest() {
  yield takeEvery(
    actions.Types.GET_CHAT_THREAD_REQUEST,
    getChatThreadRequest
  );
}

function* getUnseenContent(action) {
  try {
    const result = yield call(ChatRepository.getUnseenContent);
    yield put({
      type: actions.Types.GET_UNSEEN_CONTENT_SUCCESS,
      payload: result,
    });
  } catch (errors) {
    yield put({
      type: actions.Types.GET_UNSEEN_CONTENT_FAILURE,
      payload: errors,
    });
  }
}

function* watchGetUnseenContent() {
  yield takeLatest(
    actions.Types.GET_UNSEEN_CONTENT,
    getUnseenContent
  );
}

function* getContentUpdated(action) {
  try {
    const result = yield call(ChatRepository.getContentUpdated);
    yield put({
      type: actions.Types.GET_CONTENT_UPDATED_SUCCESS,
      payload: result,
    });
  } catch (errors) {
    yield put({
      type: actions.Types.GET_CONTENT_UPDATED_FAILURE,
      payload: errors,
    });
  }
}

function* watchGetContentUpdated() {
  yield takeLatest(
    actions.Types.GET_CONTENT_UPDATED,
    getContentUpdated
  );
}

function* createChatChannel(action) {
  try {
    const result = yield call(
      ChatRepository.createChatChannel,
      action.payload
    );
    if (result.success) {
      history.push('/authed/chat/discussion/' + result.thread_uuid);
    }
  } catch (e) {
    console.log(e);
  }
}

function* watchGetImageRequest() {
  yield takeEvery(actions.Types.GET_IMAGE, getImageToDownload);
}

function* getImageToDownload(action) {
  try {
    yield call(ChatRepository.getImageToDownload, action.payload);
  } catch (e) {
    console.log(e);
  }
}

function* watchGetDocumentRequest() {
  yield takeEvery(actions.Types.GET_DOCUMENT, getDocumentToDownload);
}

function* getDocumentToDownload(action) {
  try {
    yield call(ChatRepository.getDocumentToDownload, action.payload);
  } catch (e) {
    console.log(e);
  }
}

function* watchMarkAsReadThread() {
  yield takeLatest(
    actions.Types.MARK_AS_READ_THREAD,
    markAsReadThread
  );
}

function* markAsReadThread(action) {
  try {
    yield call(ChatRepository.markAsReadThread, action.payload);
  } catch (e) {
    console.log(e);
  }
}

function* watchGetPrivateChat() {
  yield takeLatest(actions.Types.GET_1V1_CHAT, getPrivateChat);
}

function* getPrivateChat(action) {
  try {
    const result = yield call(
      ChatRepository.get1v1ChatRequest,
      action.payload
    );
    yield put({
      type: actions.Types.SET_1V1_CHAT,
      payload: result.thread,
    });

    if (result.success) {
      history.push('/authed/chat/discussion/' + result.thread.uuid);
    }
  } catch (e) {
    console.log(e);
  }
}

function* watchCreateChatChannelRequest() {
  yield takeLatest(
    actions.Types.CREATE_CHAT_CHANNEL_REQUEST,
    createChatChannel
  );
}

function* editChatChannel(action) {
  try {
    const result = yield call(
      ChatRepository.editChatChannel,
      action.payload
    );
  } catch (e) {
    console.log(e);
  }
}

function* watchEditChatChannelRequest() {
  yield takeLatest(actions.Types.EDIT_CHAT_REQUEST, editChatChannel);
}

function* deleteChatChannel(action) {
  try {
    const result = yield call(
      ChatRepository.deleteChatChannel,
      action.payload
    );

    yield put({
      type: actions.Types.DELETE_CHAT_SUCCESS,
      payload: action.payload.thread_uuid,
    });
  } catch (e) {
    console.log(e);
  }
}

function* watchDeleteChatChannelRequest() {
  yield takeLatest(
    actions.Types.DELETE_CHAT_REQUEST,
    deleteChatChannel
  );
}

function* addMemberChatChannel(action) {
  try {
    const result = yield call(
      ChatRepository.addMembersChatChannel,
      action.payload
    );
  } catch (e) {
    console.log(e);
  }
}

function* watchAddMembersChatChannelRequest() {
  yield takeLatest(
    actions.Types.ADD_CHAT_MEMBERS,
    addMemberChatChannel
  );
}

function* removeMemberChatChannel(action) {
  try {
    const result = yield call(
      ChatRepository.removeMembersChatChannel,
      action.payload
    );
    store.dispatch(getMembersRequest(action.payload.thread_uuid));
  } catch (e) {
    console.log(e);
  }
}

function* watchRemoveMembersChatChannelRequest() {
  yield takeLatest(
    actions.Types.REMOVE_CHAT_MEMBERS,
    removeMemberChatChannel
  );
}

function getSenderuuid(state) {
  return state?.User?.myDetail?.user?.uuid;
}

function* postChatMessage(action) {
  const sender_uuid = yield select(getSenderuuid);
  try {
    if (
      !action.payload.image_identifier &&
      !action.payload.document
    ) {
      yield put(
        actions.addNewMessageToChatThreadAsPendingStatus({
          ...action.payload,
          sender_uuid,
        })
      );
    }
    const result = yield call(
      ChatRepository.postChatMessage,
      action.payload
    );
    if (result.success) {
      /*
      const newMessageResult = yield call(ChatRepository.getThreadMessages, {
        thread_uuid: action.payload.thread_uuid,
        page: null,
        limit: null,
        before_message_time: null,
        after_message_number: action.payload.after_message_number,
      });

      yield put({
        type: actions.Types.GET_THREAD_MESSAGES_SUCCESS,
        payload: newMessageResult,
      });
*/
      // yield put(
      //   actions.getNewestUnreadMessagesFromChatThreadRequest({
      //     thread_uuid: action.payload.thread_uuid,
      //     page: 1,
      //     limit: 1,
      //   })
      // );
      // yield put(actions.getAllMessagesFromChatThreadRequest(action.payload.thread_uuid));
      /*
      yield put(
        actions.removeMessageFromTheChatTread({
          ...action.payload,
          sender_uuid,
        })
      );*/
    }
  } catch (e) {
    yield put(
      actions.handleFailedPostedNewMessageToChatThreadRequest({
        ...action.payload,
        sender_uuid,
      })
    );
    console.log(e);
  }
}

function* watchPostNewMessageToChatThreadRequest() {
  yield takeEvery(
    actions.Types.POST_NEW_MESSAGE_TO_CHAT_THREAD_REQUEST,
    postChatMessage
  );
}

function* sendImageChatMessage(action) {
  try {
    const result = yield call(
      ChatRepository.postChatMessage,
      action.payload
    );
    if (result.success) {
      yield put(
        actions.getThreadMessagesRequest(action.payload.thread_uuid)
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* watchSendImageChatThreadRequest() {
  yield takeEvery(
    actions.Types.SEND_IMAGE_ATTACHMENT,
    sendImageChatMessage
  );
}

function* getThreadMessages(action) {
  try {
    const result = yield call(
      ChatRepository.getThreadMessages,
      action.payload
    );
    yield put({
      type: actions.Types.GET_THREAD_MESSAGES_SUCCESS,
      payload: result,
    });
  } catch (e) {
    console.log('ON THREAD REQUEST WORKER ERROR', action);
  }
}

function* watchGetThreadMessagesRequest() {
  yield takeLatest(
    actions.Types.GET_THREAD_MESSAGES_REQUEST,
    getThreadMessages
  );
}

function* getNewestChatMassagesFromThread(action) {
  try {
    const result = yield call(
      ChatRepository.getNewestChatMassagesFromThread,
      action.payload
    );
    yield put({
      type:
        actions.Types.GET_NEWEST_MESSAGES_FROM_CHAT_THREAD_SUCCESS,
      payload: {
        page: action.payload.page,
        messages: result.messages,
      },
    });
  } catch (e) {
    console.log('ON THREAD REQUEST WORKER ERROR', action);
  }
}

function* watchGetNewestMessagesFromChatThreadRequest() {
  yield takeLatest(
    actions.Types.GET_NEWEST_MESSAGES_FROM_CHAT_THREAD_REQUEST,
    getNewestChatMassagesFromThread
  );
}

function* getNewestUnreadChatMassagesFromThread(action) {
  try {
    const result = yield call(
      ChatRepository.getNewestChatMassagesFromThread,
      action.payload
    );
    yield put({
      type:
        actions.Types
          .GET_NEWEST_UNREAD_MESSAGES_FROM_CHAT_THREAD_SUCCESS,
      payload: {
        page: action.payload.page,
        messages: result.messages,
        unread_messages: result.unread_messages,
      },
    });
  } catch (e) {
    console.log('ON THREAD REQUEST WORKER ERROR', action);
  }
}

function* watchGetNewestUnreadMessagesFromChatThreadRequest() {
  yield takeLatest(
    actions.Types.GET_NEWEST_UNREAD_MESSAGES_FROM_CHAT_THREAD_REQUEST,
    getNewestUnreadChatMassagesFromThread
  );
}

// search discussion groups and chats
function* watchSearchThread() {
  yield takeLatest(
    `${actions.Types.SEARCH_CHAT_THREAD}_REQUEST`,
    function*({ payload }) {
      try {
        const threads = yield call(
          ChatRepository.searchChatThreads,
          payload
        );
        yield put({
          type: `${actions.Types.SEARCH_CHAT_THREAD}_SUCCESS`,
          payload: threads,
        });
      } catch (errors) {
        yield put({
          type: `${actions.Types.SEARCH_CHAT_THREAD}_FAILURE`,
          payload: errors,
        });
      }
    }
  );
}

const chatSagas = [
  fork(watchGetChatChannelsRequest),
  fork(watchCreateChatChannelRequest),
  fork(watchPostNewMessageToChatThreadRequest),
  fork(watchGetThreadMessagesRequest),
  fork(watchSendImageChatThreadRequest),
  fork(watchGetChatChannelMembersRequest),
  fork(watchEditChatChannelRequest),
  fork(watchAddMembersChatChannelRequest),
  fork(watchGetImageRequest),
  fork(watchGetDocumentRequest),
  fork(watchMarkAsReadThread),
  fork(watchGetContentUpdated),
  fork(watchGetUnseenContent),
  fork(watchGetChatThreadRequest),
  fork(watchGetPrivateChat),
  fork(watchGetNewestMessagesFromChatThreadRequest),
  fork(watchGetNewestUnreadMessagesFromChatThreadRequest),
  fork(watchDeleteChatChannelRequest),
  fork(watchRemoveMembersChatChannelRequest),
  fork(watchSearchThread),
];

export default chatSagas;
