import createReducer from "../lib/utils/CreateReducer";
import OrganizationConstants from "../constants/OrganizationConstants";
import _unionBy from "lodash/unionBy"

const {
  GET_ORGANIZATION_DETAIL,
  JOIN_ORGANIZATION,
  GET_ORGANIZATION_LIST,
  SEARCH_ORGANIZATION,
} = OrganizationConstants;

export const getInitialState = () => ({
  loading: false,
  errors: {},
  organizationDetail: {},
  organizations: [],
  organizationList: [],
  submitted: false,
});

export default createReducer(getInitialState, {
  [`${GET_ORGANIZATION_DETAIL}_REQUEST`]: () => ({
    loading: true,
    errors: {},
  }),
  [`${GET_ORGANIZATION_DETAIL}_SUCCESS`]: (
    state,
    { payload: organizationDetail }
  ) => ({
    loading: false,
    organizationDetail,
  }),
  [`${GET_ORGANIZATION_DETAIL}_FAILURE`]: (state, { payload: errors }) => ({
    loading: false,
    errors,
  }),
  //
  [`${GET_ORGANIZATION_LIST}_REQUEST`]: () => ({
    loading: true,
    errors: {},
  }),
  [`${GET_ORGANIZATION_LIST}_SUCCESS`]: (
    state,
    { payload: organizationList }
  ) => ({
    loading: false,
    organizationList: _unionBy(
      state.organizationList,
      organizationList,
      "uuid"
    ),
  }),
  [`${GET_ORGANIZATION_LIST}_FAILURE`]: (state, { payload: errors }) => ({
    loading: false,
    errors,
  }),
  //
  [`${JOIN_ORGANIZATION}_SUCCESS`]: (state, action) => ({
    loading: false,
    submitted: false,
  }),
  [`${JOIN_ORGANIZATION}_FAILURE`]: (state, { payload: errors }) => ({
    loading: false,
    submitted: false,
    errors,
  }),

  [`${SEARCH_ORGANIZATION}_SUCCESS`]: (state, { payload: organizations }) => ({
    loading: false,
    organizations,
  }),
  [`${SEARCH_ORGANIZATION}_FAILURE`]: (state, { payload: errors }) => ({
    loading: false,
    errors,
  }),
});
