import { combineReducers } from "redux";

import User from "./UserReducer";
import Organization from "./OrganizationReducer";
import Schedule from "./ScheduleReducer";
import Auth from "./AuthReducer";
import AppConfig from "./AppConfigReducer";
import AlertBox from "./AlertBoxReducer";
import Common from "./CommonReducer";
import ChatCall from "./ChatCallReducer";
import Chat from "./ChatReducer";

export default combineReducers({
  User,
  Organization,
  Schedule,
  Auth,
  AppConfig,
  AlertBox,
  Common,
  ChatCall,
  Chat
});
