import createReducer from "../lib/utils/CreateReducer";
import CommonConstants from "../constants/CommonConstants";

const {
  GET_DOCS,
  GET_CALL_TOKEN,
  SEARCH_SUGGESTION,
  GET_POST,
} = CommonConstants;

export const getInitialState = () => ({
  loading: false,
  errors: {},
  docs: "",
  token: {},
  searchResults: [],
  searchInput: "",
  post: {},
});

export default createReducer(getInitialState, {
  [`${GET_DOCS}_REQUEST`]: () => ({
    loading: true,
    errors: {},
  }),
  [`${GET_DOCS}_SUCCESS`]: (state, { payload: docs }) => ({
    loading: false,
    docs,
  }),
  [`${GET_DOCS}_FAILURE`]: (state, { payload: errors }) => ({
    loading: false,
    errors,
  }),
  [`${GET_CALL_TOKEN}_SUCCESS`]: (state, { payload: token }) => ({
    loading: false,
    token,
  }),
  [`${GET_CALL_TOKEN}_FAILURE`]: (state, { payload: errors }) => ({
    loading: false,
    errors,
  }),
  [`${SEARCH_SUGGESTION}_REQUEST`]: (state, { payload: { searchInput } }) => ({
    loading: true,
    searchInput,
    searchResults: [],
  }),
  [`${SEARCH_SUGGESTION}_SUCCESS`]: (state, { payload: searchResults }) => ({
    loading: false,
    searchResults,
  }),
  [`${SEARCH_SUGGESTION}_FAILURE`]: (state, { payload: errors }) => ({
    loading: false,
    errors,
  }),
  [`${GET_POST}_REQUEST`]: () => ({
    loading: true,
  }),
  [`${GET_POST}_SUCCESS`]: (state, { payload: post }) => ({
    loading: false,
    post,
  }),
  [`${GET_POST}_FAILURE`]: (state, { payload: errors }) => ({
    loading: false,
    errors,
  }),
});
