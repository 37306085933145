import _includes from "lodash/includes";

export const Types = {
  RESET_CHAT_SCREEN: "chat/reset_chat_screen",
  GET_CHAT_CHANNELS_REQUEST: "chat/get_channels_request",
  GET_CHAT_CHANNELS_SUCCESS: "chat/get_channels_success",
  GET_CHAT_CHANNELS_FAILURE: "chat/get_channels_failure",
  GET_CHAT_THREAD_REQUEST: "chat/get_thread_request",
  GET_CHAT_THREAD_SUCCESS: "chat/get_thread_success",
  GET_CHAT_THREAD_FAILURE: "chat/get_thread_failure",
  CREATE_CHAT_CHANNEL_REQUEST: "chat/create_channel_request",
  CREATE_CHAT_CHANNEL_SUCCESS: "chat/create_channel_success",
  CREATE_CHAT_CHANNEL_FAILURE: "chat/create_channel_failure",
  ADD_NEW_MESSAGE_TO_CHAT_THREAD_AS_PENDING_STATUS:
    "chat/add_new_message_to_thread_as_pending_status",
  REMOVE_MESSAGE_FROM_CHAT_THREAD: "chat/remove_message_from_chat_tread",
  POST_NEW_MESSAGE_TO_CHAT_THREAD_REQUEST:
    "chat/post_new_message_to_thread_request",
  POST_NEW_MESSAGE_TO_CHAT_THREAD_SUCCESS:
    "chat/post_new_message_to_thread_success",
  POST_NEW_MESSAGE_TO_CHAT_THREAD_FAILURE:
    "chat/post_new_message_to_thread_failure",

  GET_THREAD_MESSAGES_REQUEST: "chat/get_thread_messages_request",
  GET_THREAD_MESSAGES_SUCCESS: "chat/get_thread_messages_success",
  GET_THREAD_MESSAGES_FAILURE: "chat/get_thread_messages_failure",

  GET_NEWEST_MESSAGES_FROM_CHAT_THREAD_REQUEST:
    "chat/get_newest_messages_from_chat_thread_request",
  GET_NEWEST_MESSAGES_FROM_CHAT_THREAD_SUCCESS:
    "chat/get_newest_messages_from_chat_thread_success",
  GET_NEWEST_MESSAGES_FROM_CHAT_THREAD_FAILURE:
    "chat/get_newest_messages_from_chat_thread_failure",

  GET_NEWEST_UNREAD_MESSAGES_FROM_CHAT_THREAD_REQUEST:
    "chat/get_newest_unread_messages_from_chat_thread_request",
  GET_NEWEST_UNREAD_MESSAGES_FROM_CHAT_THREAD_SUCCESS:
    "chat/get_newest_unread_messages_from_chat_thread_success",
  GET_NEWEST_UNREAD_MESSAGES_FROM_CHAT_THREAD_FAILURE:
    "chat/get_newest_unread_messages_from_chat_thread_failure",

  SET_CHAT_CHANNEL_TITLE: "chat/set_chat_channel_title",
  SEND_IMAGE_ATTACHMENT: "chat/send_image_attachment",
  EDIT_CHAT_REQUEST: "chat/edit_chat_request",
  ADD_CHAT_MEMBERS: "chat/add_chat_memebers",
  REMOVE_CHAT_MEMBERS: "chat/remove_chat_memeber",

  GET_CHAT_MEMBERS: "chat/get_chat_memebers",
  GET_CHAT_MEMBERS_SUCCESS: "chat/get_chat_memebers_success",
  GET_CHAT_MEMBERS_FAILURE: "chat/get_chat_memebers_success_failure",
  GET_IMAGE: "chat/get_image",
  GET_DOCUMENT: "chat/get_document",
  MARK_AS_READ_THREAD: "chat/mark_as_read_thread",
  GET_1V1_CHAT: "chat/get_1v1_chat",
  SET_1V1_CHAT: "chat/set_1v1_chat",

  GET_CONTENT_UPDATED: "chat/get_content_updated",
  GET_CONTENT_UPDATED_SUCCESS: "chat/get_content_updated_success",
  GET_CONTENT_UPDATED_FAILURE: "chat/get_content_updated_failure",
  GET_UNSEEN_CONTENT: "chat/get_unseen_content",
  GET_UNSEEN_CONTENT_SUCCESS: "chat/get_unseen_content_success",
  GET_UNSEEN_CONTENT_FAILURE: "chat/get_unseen_content_failure",

  DELETE_CHAT_REQUEST: "chat/delete_chat_request",
  DELETE_CHAT_SUCCESS: "chat/delete_chat_success",

  SEARCH_CHAT_THREAD: "chat/search_chat_thread",
};

export const resetChatScreen = () => ({
  type: Types.RESET_CHAT_SCREEN,
});

export const getChatChannelsRequest = () => ({
  type: Types.GET_CHAT_CHANNELS_REQUEST,
});

export const createChatChannelRequest = ({
  subject,
  user_uuids,
  image_identifier,
}) => ({
  type: Types.CREATE_CHAT_CHANNEL_REQUEST,
  payload: { subject, user_uuids, image_identifier },
});

export const editChatChannelRequest = ({
  thread_uuid,
  subject,
  image_identifier,
}) => ({
  type: Types.EDIT_CHAT_REQUEST,
  payload: { thread_uuid, subject, image_identifier },
});

export const deleteChatChannelRequest = ({ thread_uuid }) => ({
  type: Types.DELETE_CHAT_REQUEST,
  payload: { thread_uuid },
});

export const getMembersRequest = (thread_uuid) => ({
  type: Types.GET_CHAT_MEMBERS,
  payload: { thread_uuid },
});

export const getChatThreadRequest = (thread_uuid) => ({
  type: Types.GET_CHAT_THREAD_REQUEST,
  payload: { thread_uuid },
});

export const addMembersRequest = ({ thread_uuid, users }) => ({
  type: Types.ADD_CHAT_MEMBERS,
  payload: { thread_uuid, users },
});

export const removeMembersRequest = ({ thread_uuid, user_uuid }) => ({
  type: Types.REMOVE_CHAT_MEMBERS,
  payload: { thread_uuid, user_uuid },
});

export const getThreadMessagesRequest = ({
  thread_uuid,
  limit,
  before_message_number,
  after_message_number,
}) => ({
  type: Types.GET_THREAD_MESSAGES_REQUEST,
  payload: { thread_uuid, limit, before_message_number, after_message_number },
});

export const getNewestMessagesFromChatThreadRequest = ({
  thread_uuid,
  page,
  limit,
  after_message_number,
}) => ({
  type: Types.GET_NEWEST_MESSAGES_FROM_CHAT_THREAD_REQUEST,
  payload: { thread_uuid, page, limit, after_message_number },
});

export const getNewestUnreadMessagesFromChatThreadRequest = ({
  thread_uuid,
  page,
  limit,
  read,
}) => ({
  type: Types.GET_NEWEST_UNREAD_MESSAGES_FROM_CHAT_THREAD_REQUEST,
  payload: { thread_uuid, page, limit, read },
});

export const markAsReadThreadRequest = (thread_uuid) => ({
  type: Types.MARK_AS_READ_THREAD,
  payload: { thread_uuid },
});

export const get1v1ChatRequest = (params) => ({
  type: Types.GET_1V1_CHAT,
  payload: { ...params },
});

export const getContentUpdated = () => ({
  type: Types.GET_CONTENT_UPDATED,
});

export const getUnseenContent = () => ({
  type: Types.GET_UNSEEN_CONTENT,
});

export const getImageToDownload = (link) => ({
  type: Types.GET_IMAGE,
  payload: { link },
});

export const getDocumentToDownload = (document) => ({
  type: Types.GET_DOCUMENT,
  payload: document,
});

export const postNewMessageToChatThreadRequest = ({
  thread_uuid,
  message,
  image_identifier,
  document,
  after_message_number,
}) => ({
  type: Types.POST_NEW_MESSAGE_TO_CHAT_THREAD_REQUEST,
  payload: {
    thread_uuid,
    message,
    image_identifier,
    document,
    after_message_number,
  },
});

export const handleFailedPostedNewMessageToChatThreadRequest = ({
  thread_uuid,
  message,
  image_identifier,
  sender_uuid,
}) => ({
  type: Types.POST_NEW_MESSAGE_TO_CHAT_THREAD_FAILURE,
  payload: {
    thread_uuid,
    message,
    message_body: message,
    image_identifier,
    sender_uuid,
  },
});

export const addNewMessageToChatThreadAsPendingStatus = ({
  thread_uuid,
  message,
  image_identifier,
  sender_uuid,
}) => ({
  type: Types.ADD_NEW_MESSAGE_TO_CHAT_THREAD_AS_PENDING_STATUS,
  payload: {
    thread_uuid,
    message,
    message_body: message,
    image_identifier: null,
    sender_uuid,
    type: 1,
    message_number: 1000000000,
  },
});

export const removeMessageFromTheChatTread = ({
  thread_uuid,
  message,
  image_identifier,
  sender_uuid,
}) => ({
  type: Types.REMOVE_MESSAGE_FROM_CHAT_THREAD,
  payload: { thread_uuid, message, image_identifier, sender_uuid },
});

export const sendImageAttachmentRequest = ({
  thread_uuid,
  message,
  image_identifier,
}) => ({
  type: Types.SEND_IMAGE_ATTACHMENT,
  payload: { thread_uuid, message, image_identifier },
});

export const changeChatChannelTitle = (url) => {
  let title = "";
  if (_includes(url, "chatdiscussion")) {
    title = "Chat";
  } else if (_includes(url, "createchatchannel")) {
    title = "New Message";
  } else {
    title = "Chat & Groups";
  }
  return {
    type: Types.SET_CHAT_CHANNEL_TITLE,
    payload: { title },
  };
};

const searchAllChatThreads = (keyword = "") => ({
  type: `${Types.SEARCH_CHAT_THREAD}_REQUEST`,
  payload: keyword,
});

export default {
  getChatChannelsRequest,
  createChatChannelRequest,
  getThreadMessagesRequest,
  postNewMessageToChatThreadRequest,
  changeChatChannelTitle,
  sendImageAttachmentRequest,
  editChatChannelRequest,
  addMembersRequest,
  getMembersRequest,
  getImageToDownload,
  getDocumentToDownload,
  markAsReadThreadRequest,
  getContentUpdated,
  getUnseenContent,
  getChatThreadRequest,
  get1v1ChatRequest,
  getNewestUnreadMessagesFromChatThreadRequest,
  getNewestMessagesFromChatThreadRequest,
  deleteChatChannelRequest,
  removeMembersRequest,
  searchAllChatThreads,
  resetChatScreen,
};
