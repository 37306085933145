import routes from "../lib/ApiRoutes";
import { checkResponse, get } from "../lib/FetchHelper";

export default {
  getDocs(id, type, lang) {
    return get(routes.getDocs(id, type, lang), true).then(function(response) {
      return response.text();
    });
  },
  getCallToken() {
    return get(routes.getCallToken(), {}, true).then(checkResponse);
  },
  searchSuggestion(searchInput) {
    return get(routes.searchSuggestion(searchInput), {}, true).then(
      checkResponse
    );
  },
  getPost(postId) {
    return get(routes.getPost(postId), {}, true).then(checkResponse);
  },
};
