import tokeys from "tokeys";

export default tokeys([
  "SIGNIN",
  "SIGNIN_PHONE",
  "VALIDATE_LOGIN_DATA",
  "VALIDATE_MAGIC_LOGIN_TOKEN",
  "Invalid_login_token_error_code",
  "SIGNOUT",
]);
