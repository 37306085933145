import createReducer from "../lib/utils/CreateReducer";

export const getInitialState = () => ({
  visible: false,
  alertTextLabel: "default alert text label",
  alertText: "default alert Text",
  leftOption: "buttonLeft",
  rightOption: "buttonRight",
  leftOptionVisible: false,
  rightOptionVisible: false,
  alertBottom: {
    title: "",
    text: "",
    buttonText: "",
    type: null,
    support: null
  },
  alertBottomShow: false
});

export default createReducer(getInitialState, {
  DISPLAY_ALERT: (state, { payload: { alertOptions } }) => ({
    visible: true,
    alertTextLabel: alertOptions.alertTextLabel,
    alertText: alertOptions.alertText,
    leftOption: alertOptions.leftOption,
    rightOption: alertOptions.rightOption,
    leftOptionVisible: alertOptions.leftOptionVisible,
    rightOptionVisible: alertOptions.rightOptionVisible
  }),
  HIDE_ALERT: () => ({
    visible: false
  }),
  SHOW_ALERT_BOTTOM: (state, { payload: { alertOptions } }) => ({
    alertBottom: {
      title: alertOptions.title,
      text: alertOptions.text,
      buttonText: alertOptions.buttonText,
      type: alertOptions.type,
      support: alertOptions.support,
    },
    alertBottomShow: true
  }),
  HIDE_ALERT_BOTTOM: () => ({
    alertBottomShow: false
  })
});
