import createReducer from '../lib/utils/CreateReducer';
import AppConfigConstants from '../constants/AppConfigConstants';
import AuthDataStorage from '../helpers/StorageHelpers/AuthDataStorage';

const {
  GET_APP_CONFIG,
  GET_APP_FRONTPAGE,
  GET_WORKSPACES,
  CHANGE_APP_LANGUAGE,
} = AppConfigConstants;

export const getInitialState = () => ({
  loading: false,
  errors: {},
  appConfig: {},
  app: {},
  isAppExisted: {
    appId: null,
    value: false,
  },
  elements: {},
  workspaces: [],
  appLanguage: AuthDataStorage.getLang(),
});

export default createReducer(getInitialState, {
  [`${GET_APP_CONFIG}_REQUEST`]: () => ({
    loading: true,
    errors: {},
  }),

  [`${GET_APP_CONFIG}_SUCCESS`]: (state, { payload: appConfig }) => ({
    loading: false,
    appConfig: appConfig.app,
    app: appConfig,
    isAppExisted: {
      appId: appConfig.identifier,
      value: true,
    },
  }),
  [`${GET_APP_CONFIG}_FAILURE`]: (state, { payload: errors }) => ({
    loading: false,
    errors,
    isAppExisted: {
      appId: null,
      value: false,
    },
  }),
  [`${GET_APP_FRONTPAGE}_REQUEST`]: () => ({
    loading: true,
    errors: {},
  }),
  [`${GET_APP_FRONTPAGE}_SUCCESS`]: (
    state,
    { payload: frontpage }
  ) => ({
    loading: false,
    elements: frontpage.elements,
  }),
  [`${GET_WORKSPACES}_SUCCESS`]: (
    state,
    { payload: workspaces }
  ) => ({
    loading: false,
    workspaces: workspaces,
  }),
  [`${CHANGE_APP_LANGUAGE}_SUCCESS`]: (
    state,
    { payload: language }
  ) => ({
    appLanguage: language,
  }),
});
