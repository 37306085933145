import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import reducers from "./reducers";
import config from "./config";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
const initialState = window.__INITIAL_STATE__;

const composeEnchansers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const persistConfig = {
  key: "root",
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(
  persistedReducer,
  config.isDevelopment
    ? composeEnchansers(compose(applyMiddleware(...middlewares)))
    : compose(applyMiddleware(...middlewares)),
  initialState
);

export const persistor = persistStore(store);
