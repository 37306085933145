import "bootstrap/dist/css/bootstrap.css";
import _isEmpty from "lodash/isEmpty";
import _pick from "lodash/pick";
import React, { Component, Suspense } from "react";
import { Ellipsis } from "react-awesome-spinners";
import { connect } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { bindActionCreators } from "redux";
import AlertBoxAction from "./actions/AlertBoxAction";
import AppConfigAction from "./actions/AppConfigAction";
import AuthActions from "./actions/AuthActions";
import UserActions from "./actions/UserActions";
import "./App.css";
import "./components/ScreenHeader/style.css";
import RoutePathConstants from "./constants/RoutePathConstants";
import { getToken, onTokenRefreshChange } from "./firebase";
import AuthDataStorage from "./helpers/StorageHelpers/AuthDataStorage";
import UserInfoStorage from "./helpers/StorageHelpers/UserInfoStorage";
import history from "./history";

const StartScreen = React.lazy(() => import("./screens/StartScreen"));
const ServiceTermsScreen = React.lazy(() =>
  import("./screens/ServiceTermsScreen")
);
const PrivacyPolicyScreen = React.lazy(() =>
  import("./screens/PrivacyPolicyScreen")
);
const SendFeedbackScreen = React.lazy(() =>
  import("./screens/SendFeedbackScreen")
);
const UserLoginScreen = React.lazy(() => import("./screens/LoginScreen"));
const WelcomingChatBot = React.lazy(() =>
  import("./screens/WelcomingChatBot/WelcomingChatBot")
);
const SigninWithEmailScreen = React.lazy(() =>
  import("./screens/SigninWithEmailScreen")
);
const LoadingOverlayContainer = React.lazy(() =>
  import("./containers/LoadingOverlayContainer")
);
const AuthApp = React.lazy(() => import("./components/AuthApp"));
const ProfileCreationScreen = React.lazy(() =>
  import("./screens/ProfileCreationScreen/ProfileCreationScreen")
);
const SigninScreen = React.lazy(() => import("./screens/SigninScreen"));
const EditProfile = React.lazy(() => import("./screens/EditProfile"));
const DefaultLayout = React.lazy(() => import("./components/DefaultLayout"));

const {
  dashboard,
  startScreen,
  loginScreen,
  welcomingScreen,
  magicLogin,
  serviceTermsGuest,
  privacyPolicyGuest,
  appSendFeedbackguest,
  editProfileGuest,
  profileCreationGuest,
  search,
  organization,
  eventList,
  eventDetail,
  signinScreen,
} = RoutePathConstants;

class App extends Component {
  componentDidMount() {
    const {
      location: { pathname },
    } = history;
    const {
      AppConfig: { appConfig },
    } = this.props;
    const appId = AuthDataStorage.getAppId();
    if (_isEmpty(appConfig) && appId && appId !== "https:") {
      this.props.getAppConfig(appId);
    }

    setTimeout(() => {
      if (
        AuthDataStorage.getUserAuthentication() === "false" ||
        AuthDataStorage.getUserAuthentication() === false ||
        ((AuthDataStorage.getUserAuthentication() === "false" ||
          AuthDataStorage.getUserAuthentication() === false) &&
          UserInfoStorage.getUserRole() === "ROLE_PSEUDO") ||
        (!AuthDataStorage.getUserAuthentication() &&
          UserInfoStorage.getUserRole() === "ROLE_PSEUDO")
      ) {
        this.props.signout();
      }

      if (pathname === "/") {
        if (appId !== "https:") {
          this.handleInitialRedirection(AuthDataStorage.getAppId());
        } else {
          history.push("/https://content.mesensei.com/404/");
        }
      }
    }, 1000);

    if (pathname.includes("authed")) {
      getToken(this.setTokenFound);

      onTokenRefreshChange()
        .then((token) => {
          this.setTokenFound(token);
        })
        .catch((err) => console.log("failed: ", err));
    }
    // onMessageListener()
    //   .then((payload) => {
    //     console.log("abc", payload);
    //   })
    //   .catch((err) => console.log("failed: ", err));
  }

  setTokenFound = (token) => {
    // if (AuthDataStorage.getNotificationToken() !== token) {
    AuthDataStorage.storeNotificationToken(token);
    if (!_isEmpty(AuthDataStorage.getUuid()) && AuthDataStorage.getApiKey())
      this.props.pushNotificationTokenRequest(token);
    // }
  };

  loading = () => (
    <div className="animated fadeIn pt-3 text-center">
      <Ellipsis
        color={
          AuthDataStorage.getAppDefaultColor()
            ? AuthDataStorage.getAppDefaultColor()
            : "#dddddd"
        }
      />
    </div>
  );

  guestRoutes = () => {
    return (
      <Switch>
        <Route path={`/${startScreen}`} component={StartScreen} />
        <Route path={`/${loginScreen}`} component={UserLoginScreen} />
        <Route path={`/${welcomingScreen}`} component={WelcomingChatBot} />
        <Route path={`/${magicLogin}`} component={SigninWithEmailScreen} />
        <Route path={`/${serviceTermsGuest}`} component={ServiceTermsScreen} />
        <Route path={`/${editProfileGuest}`} component={EditProfile} />
        <Route
          path={`/${profileCreationGuest}`}
          component={ProfileCreationScreen}
        />
        <Route
          path={`/${privacyPolicyGuest}`}
          component={PrivacyPolicyScreen}
        />
        <Route path={`/${signinScreen}`} component={SigninScreen} />
        <Route
          path={`/${appSendFeedbackguest}`}
          component={SendFeedbackScreen}
        />
      </Switch>
    );
  };

  handleInitialRedirection = (appId) => {
    try {
      this.handleValidateLoginToken(appId);

      const {
        AppConfig: { appConfig },
      } = this.props;
      // Store new app id and get app config
      if (
        !AuthDataStorage.getAppId() ||
        AuthDataStorage.hasAppIdChanged(appId) ||
        _isEmpty(appConfig)
      ) {
        AuthDataStorage.storeAppId(appId);
        this.props.getAppConfig(appId);
      }

      // Redirect base on auth data
      if (
        AuthDataStorage.isAuthDataAvailable(appId) &&
        AuthDataStorage.getUserAuthentication()
      ) {
        history.push(`/${dashboard}`);
      } else {
        history.push(`/${startScreen}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleValidateLoginToken = (appId) => {
    const urlParams = new URLSearchParams(window.location.search);

    let logintoken = urlParams.get("logintoken");
    let invitetoken = urlParams.get("invitetoken");
    let isInvite = urlParams.get("invite");

    if (isInvite) {
      invitetoken = urlParams.get("logintoken");
      logintoken = null;
    }

    let userlink = urlParams.get("userlink");
    let organizationlink = urlParams.get("organizationlink");
    let eventlink = urlParams.get("eventlink");
    let sessionlink = urlParams.get("sessionlink");

    if (userlink) {
      window.location.assign(`/${search}/${userlink}`);
    } else if (organizationlink) {
      window.location.assign(`/${organization}/${organizationlink}`);
    } else if (eventlink) {
      window.location.assign(`/${eventList}/${eventlink}/schedule`);
    } else if (sessionlink) {
      window.location.assign(`/${eventDetail}/${sessionlink}`);
    } else {
      const loginData = { logintoken: logintoken, invitetoken: invitetoken };
      if (loginData.logintoken !== null || loginData.invitetoken !== null) {
        if (UserInfoStorage.getUserRole() === "ROLE_PSEUDO") {
          this.props.signout();
          AuthDataStorage.removeApiKeyAndUuid();
          UserInfoStorage.removeUserRole();
        }
        setTimeout(() => {
          this.props.validateLoginData(loginData, appId);
        }, 1000);
      }
    }
  };

  render() {
    const {
      AppConfig: { appConfig, workspaces },
      AlertBox: { alertBottomShow },
      Chat: { unseenContent },
    } = this.props;

    return (
      <Router history={history}>
        <Suspense fallback={this.loading()}>
          <DefaultLayout
            alertShow={alertBottomShow}
            appConfig={appConfig}
            pathName={window.location.pathname}
            getMyProfileDetail={this.props.getMyProfileDetail}
            getAppWorkspaces={this.props.getAppWorkspaces}
            workspacesFromApi={workspaces}
            unseenCount={unseenContent.total_app_unseen_content}
          >
            <div className="App" id="app">
              <LoadingOverlayContainer />
              <TransitionGroup className="transition-group">
                <CSSTransition timeout={450} classNames="fade">
                  <Switch>
                    <Route path="/guest">{this.guestRoutes}</Route>
                    <Route path="/authed">
                      {(props) => {
                        return <AuthApp {...props} />;
                      }}
                    </Route>
                    <Route
                      path="/:appId"
                      component={(props) => {
                        const {
                          match: {
                            params: { appId },
                          },
                        } = props;
                        const urlParams = new URLSearchParams(
                          window.location.search
                        );

                        let workspace_id = urlParams.get("workspace");
                        if (workspace_id)
                          this.handleInitialRedirection(workspace_id);
                        else if (appId) this.handleInitialRedirection(appId);

                        return <div />;
                      }}
                    />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </div>
          </DefaultLayout>
        </Suspense>
      </Router>
    );
  }
}

export default connect(
  (state) => _pick(state, ["AppConfig", "Auth", "AlertBox", "Chat"]),
  (dispatch) =>
    bindActionCreators(
      { ...AppConfigAction, ...AuthActions, ...AlertBoxAction, ...UserActions },
      dispatch
    )
)(App);
