export default {
  authDataStorageKeys: {
    appId: "app_identifier",
    appUuid: "app_uuid",
    appParentUuid: "parent_app_uuid",
    apiKey: ":apikey",
    appKey: ":appkey",
    deviceId: ":pseudo_user_identifier",
    uuid: ":uuid",
    loginCodeSecret: ":l_s",
    userAuthentication: ":user_authentication",
    appFullName: ":appFullName",
    appDefaultColor: ":app_default_color",
    lang: ":language",
    notificationToken: ":notification_token",
    pseudoName: ":pseudo_name",
  },
  userInfoStorageKeys: {
    userRole: ":user_role",
  },
};
