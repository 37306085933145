import routes from "../lib/ApiRoutes";
import { checkResponse, post, get } from "../lib/FetchHelper";

export default {
  signin(email, language) {
    return post(routes.signin(), { email, language }).then(checkResponse);
  },
  signinPhone(phone, language) {
    return post(routes.signin(), { phone, language }).then(checkResponse);
  },
  validateLoginData(loginData) {
    return post(routes.validateLoginData(), loginData).then(checkResponse);
  },
  validateMagicLoginToken(token) {
    return get(routes.validateMagicLoginToken(token), {}, true).then(
      checkResponse
    );
  },
  signout() {
    return post(routes.signout(), {}).then(checkResponse);
  },
};
