import routes from "../lib/ApiRoutes";
import { checkResponse, get, post } from "../lib/FetchHelper";

export default {
  getOrganizationDetail(organizationId) {
    return get(routes.getOrganizationDetail(organizationId), {}, true).then(
      checkResponse
    );
  },
  getOrganizationList(page) {
    return get(routes.getOrganizationList(page), {}, true).then(checkResponse);
  },
  joinOrganization(organizationId) {
    return post(routes.joinOrganization(organizationId), {}, true).then(
      checkResponse
    );
  },
  searchOrganization(searchInput) {
    return get(routes.searchOrganization(searchInput), {}, true).then(
      checkResponse
    );
  },
};
