import { takeEvery, put, call } from "redux-saga/effects";

import ChatCallConstants from "../constants/ChatCallConstants";
import ChatCallRepository from "../repositories/ChatCallRepository";

const { GET_ALL_THREADS, GET_THREAD, POST_THREAD } = ChatCallConstants;

export function* watchGetAllThreads() {
  yield takeEvery(`${GET_ALL_THREADS}_REQUEST`, function*() {
    try {
      const threads = yield call(ChatCallRepository.getAllThreads);

      yield put({
        type: `${GET_ALL_THREADS}_SUCCESS`,
        payload: threads
      });
    } catch (errors) {
      yield put({
        type: `${GET_ALL_THREADS}_FAILURE`,
        payload: errors
      });
    }
  });
}

export function* watchGetThread() {
  yield takeEvery(`${GET_THREAD}_REQUEST`, function*({ payload: id }) {
    try {
      const thread = yield call(ChatCallRepository.getThread, id);

      yield put({
        type: `${GET_THREAD}_SUCCESS`,
        payload: thread
      });
    } catch (errors) {
      yield put({
        type: `${GET_THREAD}_FAILURE`,
        payload: errors
      });
    }
  });
}

export function* watchPostThread() {
  yield takeEvery(`${POST_THREAD}_REQUEST`, function*({ payload: params }) {
    try {
      const thread = yield call(ChatCallRepository.postThread, params);
      yield put({
        type: `${POST_THREAD}_SUCCESS`,
        payload: thread
      });
    } catch (errors) {
      yield put({
        type: `${POST_THREAD}_FAILURE`,
        payload: errors
      });
    }
  });
}
