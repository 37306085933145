import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

import CommonConstants from "../constants/CommonConstants";
import CommonRepository from "../repositories/CommonRepository";

const {
  GET_DOCS,
  GET_CALL_TOKEN,
  SEARCH_SUGGESTION,
  GET_POST,
} = CommonConstants;

export function* watchGetDocs() {
  yield takeLatest(`${GET_DOCS}_REQUEST`, function*({ payload: params }) {
    try {
      const docs = yield call(
        CommonRepository.getDocs,
        params.id,
        params.type,
        params.lang
      );
      yield put({
        type: `${GET_DOCS}_SUCCESS`,
        payload: docs,
      });
    } catch (errors) {
      yield put({
        type: `${GET_DOCS}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchGetCallToken() {
  yield takeEvery(`${GET_CALL_TOKEN}_REQUEST`, function*() {
    try {
      const token = yield call(CommonRepository.getCallToken);
      yield put({
        type: `${GET_CALL_TOKEN}_SUCCESS`,
        payload: token,
      });
    } catch (errors) {
      yield put({
        type: `${GET_CALL_TOKEN}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchSearchSuggestion() {
  yield takeLatest(`${SEARCH_SUGGESTION}_REQUEST`, function*({
    payload: { searchInput },
  }) {
    try {
      const searchResults = yield call(
        CommonRepository.searchSuggestion,
        searchInput
      );

      yield put({
        type: `${SEARCH_SUGGESTION}_SUCCESS`,
        payload: searchResults.items,
      });
    } catch (errors) {
      yield put({
        type: `${SEARCH_SUGGESTION}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchGetPost() {
  yield takeLatest(`${GET_POST}_REQUEST`, function*({ payload: { postId } }) {
    try {
      const post = yield call(CommonRepository.getPost, postId);

      yield put({
        type: `${GET_POST}_SUCCESS`,
        payload: post.post,
      });
    } catch (errors) {
      yield put({
        type: `${GET_POST}_FAILURE`,
        payload: errors,
      });
    }
  });
}
