import tokeys from 'tokeys';

export default tokeys([
  'GET_SCHEDULE_LIST',
  'CURRENT_EVENT',
  'GET_SCHEDULE_DETAIL',
  'GET_SESSION_DETAIL',
  'RESERVE_SEAT',
  'CANCEL_RESERVATION',
  'FAVOURITE_SCHEDULE',
  'REMOVE_FAVOURITE_SCHEDULE',
  'GET_MY_PAST_SESSIONS',
  'GET_MY_UPCOMING_SESSIONS'
]);
