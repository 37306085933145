import routes from '../lib/ApiRoutes';
import {
  checkResponse,
  get,
  post,
  put,
  del,
} from '../lib/FetchHelper';

export default {
  getAllChatThreads() {
    return get(routes.getAllChatThreads(), {}, true).then(
      checkResponse
    );
  },
  createChatChannel({ subject, user_uuids, image_identifier }) {
    return post(routes.createChatThread(), {
      subject,
      user_uuids,
      image_identifier,
    }).then(checkResponse);
  },

  editChatChannel({ thread_uuid, subject, image_identifier }) {
    return put(routes.editChatThread(thread_uuid), {
      subject,
      image_identifier,
    }).then(checkResponse);
  },

  deleteChatChannel({ thread_uuid }) {
    return del(routes.deleteChatThread(thread_uuid), {}).then(
      checkResponse
    );
  },

  getMembersChatChannel({ thread_uuid }) {
    return get(
      routes.getMembersChatThread(thread_uuid),
      {},
      true
    ).then(checkResponse);
  },

  getChatThreadRequest({ thread_uuid }) {
    return get(
      routes.getChatThreadRequest(thread_uuid),
      {},
      true
    ).then(checkResponse);
  },

  getImageToDownload({ link }) {
    return get(routes.ImageToDownload(link), {}, true)
      .then(
        (response) => response.blob() // var newBlob = new Blob([response.clone().blob()], { type: "image/jpeg" });
      )
      .then((myBlob) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(myBlob, 'image');
        } else {
          const data = window.URL.createObjectURL(myBlob);
          var link = document.createElement('a');
          link.href = data;
          link.download = 'image.jpeg';
          link.click();
          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      });
  },

  getDocumentToDownload(downloadDocument) {
    return get(
      routes.DocumentToDownload(downloadDocument.identifier),
      {},
      true
    )
      .then(
        (response) => response.blob() // var newBlob = new Blob([response.clone().blob()], { type: "image/jpeg" });
      )
      .then((myBlob) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(myBlob, 'document');
        } else {
          const data = window.URL.createObjectURL(myBlob);
          var link = document.createElement('a');
          link.href = data;
          link.download =
            downloadDocument.name + '.' + downloadDocument.extension;
          link.click();
          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      });
  },

  addMembersChatChannel({ thread_uuid, users }) {
    return put(routes.addMembersChatThread(thread_uuid), {
      users,
    }).then(checkResponse);
  },

  removeMembersChatChannel({ thread_uuid, user_uuid }) {
    return del(
      routes.removeMemberFromChat(thread_uuid, user_uuid),
      {}
    ).then(checkResponse);
  },

  postChatMessage({
    thread_uuid,
    message,
    image_identifier,
    document,
    after_message_number,
  }) {
    return post(routes.postNewChatMessageToThread(thread_uuid), {
      message,
      image_identifier,
      document,
      after_message_number,
    }).then(checkResponse);
  },

  getThreadMessages({
    thread_uuid,
    limit,
    before_message_number,
    after_message_number,
  }) {
    return get(
      routes.getThreadMessages(
        thread_uuid,
        limit,
        before_message_number,
        after_message_number
      ),
      {},
      true
    ).then(checkResponse);
  },

  getNewestChatMassagesFromThread({
    thread_uuid,
    page,
    limit,
    after_message_number,
  }) {
    return get(
      routes.getNewestChatMassagesFromThread(
        thread_uuid,
        page,
        limit,
        after_message_number
      ),
      {},
      true
    ).then(checkResponse);
  },

  markAsReadThread({ thread_uuid }) {
    return post(routes.markAsReadThread(thread_uuid), {}, true).then(
      checkResponse
    );
  },

  get1v1ChatRequest({ recipient_uuid }) {
    return get(
      routes.get1v1ChatRequest(recipient_uuid),
      {},
      true
    ).then(checkResponse);
  },
  getUnseenContent() {
    return get(routes.getUnseenContent(), {}, true).then(
      checkResponse
    );
  },
  getContentUpdated() {
    return get(routes.getContentUpdated(), {}, true).then(
      checkResponse
    );
  },
  // search threads for discussion
  searchChatThreads(keyword) {
    return get(routes.searchChatThreads(keyword), {}, true).then(
      checkResponse
    );
  },
};
