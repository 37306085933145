import AlertBoxConstants from '../constants/AlertBoxConstants';

const { DISPLAY_ALERT, HIDE_ALERT, SHOW_ALERT_BOTTOM, HIDE_ALERT_BOTTOM } = AlertBoxConstants;

export const alertBoxDisplay = alertOptions => ({
  type: DISPLAY_ALERT,
  payload: { alertOptions }
});

export const alertBoxHide = () => ({
  type: HIDE_ALERT
});

export const alertBottomDisplay = alertOptions => ({
  type: SHOW_ALERT_BOTTOM,
  payload: { alertOptions }
});

export const alertBottomHide = () => ({
  type: HIDE_ALERT_BOTTOM
});


export default {
  alertBoxDisplay,
  alertBoxHide,
  alertBottomDisplay,
  alertBottomHide
};
