import { takeEvery, put, call } from "redux-saga/effects";

import OrganizationConstants from "../constants/OrganizationConstants";
import OrganizationRepository from "../repositories/OrganizationRepository";

const {
  GET_ORGANIZATION_DETAIL,
  JOIN_ORGANIZATION,
  SEARCH_ORGANIZATION,
  GET_ORGANIZATION_LIST,
} = OrganizationConstants;

export function* watchJoinOrganization() {
  yield takeEvery(`${JOIN_ORGANIZATION}_REQUEST`, function*({
    payload: { organizationId },
  }) {
    try {
      yield call(OrganizationRepository.joinOrganization, organizationId);

      yield put({
        type: `${JOIN_ORGANIZATION}_SUCCESS`,
      });
    } catch (errors) {
      yield put({
        type: `${JOIN_ORGANIZATION}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchGetOrganizationDetail() {
  yield takeEvery(`${GET_ORGANIZATION_DETAIL}_REQUEST`, function*({
    payload: { organizationId },
  }) {
    try {
      const organizationDetail = yield call(
        OrganizationRepository.getOrganizationDetail,
        organizationId
      );

      yield put({
        type: `${GET_ORGANIZATION_DETAIL}_SUCCESS`,
        payload: organizationDetail.organization,
      });
    } catch (errors) {
      yield put({
        type: `${GET_ORGANIZATION_DETAIL}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchSearchOrganizationDetail() {
  yield takeEvery(`${SEARCH_ORGANIZATION}_REQUEST`, function*({
    payload: { searchInput },
  }) {
    try {
      const organizations = yield call(
        OrganizationRepository.searchOrganization,
        searchInput
      );

      yield put({
        type: `${SEARCH_ORGANIZATION}_SUCCESS`,
        payload: organizations.organizations,
      });
    } catch (errors) {
      yield put({
        type: `${SEARCH_ORGANIZATION}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchGetOrganizationList() {
  yield takeEvery(`${GET_ORGANIZATION_LIST}_REQUEST`, function*({
    payload: { page },
  }) {
    try {
      const organizationList = yield call(
        OrganizationRepository.getOrganizationList,
        page
      );

      yield put({
        type: `${GET_ORGANIZATION_LIST}_SUCCESS`,
        payload: organizationList.organizations,
      });
    } catch (errors) {
      yield put({
        type: `${GET_ORGANIZATION_LIST}_FAILURE`,
        payload: errors,
      });
    }
  });
}
