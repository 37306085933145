import tokeys from "tokeys";

export default tokeys([
  "GET_PRIVACY",
  "GET_TERMS",
  "GET_DOCS",
  "GET_CALL_TOKEN",
  "SEARCH_SUGGESTION",
  "GET_POST",
]);
