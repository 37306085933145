import routes from "../lib/ApiRoutes";
import { checkResponse, get } from "../lib/FetchHelper";

export default {
  getAllThreads() {
    return get(routes.getAllThreads(), {}, true).then(checkResponse);
  },
  getThread(id) {
    return get(routes.getThread(id), {}, true).then(checkResponse);
  },
  postThread(params) {
    return get(routes.postThread(params), {}, true).then(checkResponse);
  },
};
