import AppConfigConstants from "../constants/AppConfigConstants";

const {
  GET_APP_CONFIG,
  GET_APP_FRONTPAGE,
  GET_WORKSPACES,
  CHANGE_APP_LANGUAGE,
  PUSH_NOTIFICATION_TOKEN,
} = AppConfigConstants;

export const getAppConfig = (appIdentifier) => ({
  type: `${GET_APP_CONFIG}_REQUEST`,
  payload: { appIdentifier },
});

export const getAppFrontpage = () => ({
  type: `${GET_APP_FRONTPAGE}_REQUEST`,
});

export const getAppWorkspaces = () => ({
  type: `${GET_WORKSPACES}_REQUEST`,
});

export const changeAppLanguage = (language) => ({
  type: `${CHANGE_APP_LANGUAGE}_REQUEST`,
  payload: { language },
});

export const pushNotificationTokenRequest = (token) => ({
  type: `${PUSH_NOTIFICATION_TOKEN}_REQUEST`,
  payload: { token },
});

export default {
  getAppConfig,
  getAppFrontpage,
  getAppWorkspaces,
  changeAppLanguage,
  pushNotificationTokenRequest,
};
