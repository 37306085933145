import createReducer from "../lib/utils/CreateReducer";
import ChatCallConstants from "../constants/ChatCallConstants";

const { GET_THREAD, GET_ALL_THREADS } = ChatCallConstants;

export const getInitialState = () => ({
  loading: false,
  errors: {},
  threads: [],
  thread: {}
});

export default createReducer(getInitialState, {
  [`${GET_ALL_THREADS}_SUCCESS`]: (state, { payload: docs }) => ({
    loading: false,
    docs
  }),
  [`${GET_ALL_THREADS}_FAILURE`]: (state, { payload: errors }) => ({
    loading: false,
    errors
  }),
  [`${GET_THREAD}_SUCCESS`]: (state, { payload: token }) => ({
    loading: false,
    token
  }),
  [`${GET_THREAD}_FAILURE`]: (state, { payload: errors }) => ({
    loading: false,
    errors
  })
});
