import StorageKeysConstants from "../../constants/StorageKeysConstants";
import LocalStorage from "../../lib/LocalStorage";

const { authDataStorageKeys } = StorageKeysConstants;

export default {
  getAppId: () => LocalStorage.get(authDataStorageKeys.appId),
  getAppParentUuid: () => LocalStorage.get(authDataStorageKeys.appParentUuid),
  storeAppParentUuid: (parentUuid) => {
    LocalStorage.set(authDataStorageKeys.appParentUuid, parentUuid);
  },
  // isAppIdAvailable: () => (this.getAppId() !== null),
  hasAppIdChanged: (newAppId) =>
    LocalStorage.get(authDataStorageKeys.appId) !== newAppId,
  storeAppId: (appId) => {
    if (appId) {
      LocalStorage.set(authDataStorageKeys.appId, appId);
    }
  },
  storeNotificationToken: (token) => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);
    if (token) {
      LocalStorage.set(
        `${appId}${authDataStorageKeys.notificationToken}`,
        token
      );
    }
  },
  getNotificationToken: () => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);
    return LocalStorage.get(`${appId}${authDataStorageKeys.notificationToken}`);
  },
  storeApiUuid: (uuid) => {
    if (uuid) {
      LocalStorage.set(authDataStorageKeys.appUuid, uuid);
    }
  },
  getAppUuid: () => LocalStorage.get(authDataStorageKeys.appUuid),
  isAuthDataAvailable: (appId) =>
    LocalStorage.get(authDataStorageKeys.appId) &&
    LocalStorage.get(`${appId}${authDataStorageKeys.apiKey}`),

  storeSmartAppBannerBottom: (show) => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);
    LocalStorage.set(`${appId}:banner_bottom`, show);
  },

  storeSmartAppBannerTop: (show) => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);
    LocalStorage.set(`${appId}:banner_top`, show);
  },

  getDeviceId: () =>
    LocalStorage.get(
      `${LocalStorage.get(authDataStorageKeys.appId)}${
        authDataStorageKeys.deviceId
      }`
    ),
  storeDeviceId: (deviceId) => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);

    if (appId && deviceId) {
      LocalStorage.set(`${appId}${authDataStorageKeys.deviceId}`, deviceId);
    }
  },
  storeAppName: (appName, workspace) => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);

    if (appId && appName) {
      LocalStorage.set(`${appId}${authDataStorageKeys.appFullName}`, appName);
    }
  },
  storeAppDefaultColor: (appDefaultColor) => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);

    if (appId && appDefaultColor) {
      LocalStorage.set(
        `${appId}${authDataStorageKeys.appDefaultColor}`,
        appDefaultColor
      );
    }
  },
  getAppWorkspaces: () => JSON.parse(LocalStorage.get("appsWorkspaces")),
  initAppWorkspaces: (uuid, mainApp) => {
    let appsWorkspaces = {};
    appsWorkspaces[uuid] = {
      ...mainApp,
      workspaces: [],
    };

    if (uuid) {
      LocalStorage.set(`appsWorkspaces`, JSON.stringify(appsWorkspaces));
    }
  },
  storeNewAppWorkspaces: (uuid, mainApp) => {
    const appsWorkspaces = JSON.parse(LocalStorage.get("appsWorkspaces"));

    appsWorkspaces[uuid] = {
      ...mainApp,
      workspaces: appsWorkspaces[uuid]
        ? appsWorkspaces[uuid].workspaces
          ? appsWorkspaces[uuid].workspaces
          : []
        : [],
    };

    if (uuid) {
      LocalStorage.set(`appsWorkspaces`, JSON.stringify(appsWorkspaces));
    }
  },
  storeAppWorkspacesList: (parentAppUuid, workspace) => {
    let appsWorkspaces = JSON.parse(LocalStorage.get("appsWorkspaces"));
    let workspacesToUpdate = appsWorkspaces[parentAppUuid]
      ? appsWorkspaces[parentAppUuid].workspaces
        ? appsWorkspaces[parentAppUuid].workspaces
        : []
      : [];
    if (
      workspacesToUpdate.find(function(el) {
        return (
          el.app_uuid === workspace.app_uuid || el.uuid === workspace.app_uuid
        );
      }) === undefined
    )
      appsWorkspaces[parentAppUuid] = {
        ...appsWorkspaces[parentAppUuid],
        workspaces: [...workspacesToUpdate, workspace],
      };

    LocalStorage.set(`appsWorkspaces`, JSON.stringify(appsWorkspaces));
  },
  editAppWorkspace: (parentAppUuid, workspacesFromApi) => {
    let appsWorkspaces = JSON.parse(LocalStorage.get("appsWorkspaces"));
    let workspacesToUpdate = appsWorkspaces[parentAppUuid]
      ? appsWorkspaces[parentAppUuid].workspaces
        ? appsWorkspaces[parentAppUuid].workspaces
        : []
      : [];

    let filteredArray = workspacesToUpdate.filter((o) =>
      workspacesFromApi.some((i) => i.app_uuid === o.app_uuid)
    );
    appsWorkspaces[parentAppUuid] = {
      ...appsWorkspaces[parentAppUuid],
      workspaces: filteredArray,
    };

    LocalStorage.set(`appsWorkspaces`, JSON.stringify(appsWorkspaces));
  },
  storeApiKey: (apiKey) => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);

    if (appId && apiKey) {
      LocalStorage.set(`${appId}${authDataStorageKeys.apiKey}`, apiKey);
    }
  },
  storeUuid: (uuid) => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);

    if (appId && uuid) {
      LocalStorage.set(`${appId}${authDataStorageKeys.uuid}`, uuid);
    }
  },

  storeLoginCodeSecret: (key) => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);

    if (appId && key) {
      LocalStorage.set(`${appId}${authDataStorageKeys.loginCodeSecret}`, key);
    }
  },

  storeAppKey: (appKey) => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);

    if (appId && appKey) {
      LocalStorage.set(`${appId}${authDataStorageKeys.appKey}`, appKey);
    }
  },
  storeAuthentication: (authentication) => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);

    if (appId && authentication !== null) {
      LocalStorage.set(
        `${appId}${authDataStorageKeys.userAuthentication}`,
        authentication
      );
    }
  },
  getApiKey: () =>
    LocalStorage.get(
      `${LocalStorage.get(authDataStorageKeys.appId)}${
        authDataStorageKeys.apiKey
      }`
    ),
  getUuid: () =>
    LocalStorage.get(
      `${LocalStorage.get(authDataStorageKeys.appId)}${
        authDataStorageKeys.uuid
      }`
    ),

  getLoginCodeSecret: () =>
    LocalStorage.get(
      `${LocalStorage.get(authDataStorageKeys.appId)}${
        authDataStorageKeys.loginCodeSecret
      }`
    ),

  getAppKey: () =>
    LocalStorage.get(
      `${LocalStorage.get(authDataStorageKeys.appId)}${
        authDataStorageKeys.appKey
      }`
    ),
  getAppName: () =>
    LocalStorage.get(
      `${LocalStorage.get(authDataStorageKeys.appId)}${
        authDataStorageKeys.appFullName
      }`
    ),
  getAppDefaultColor: () =>
    LocalStorage.get(
      `${LocalStorage.get(authDataStorageKeys.appId)}${
        authDataStorageKeys.appDefaultColor
      }`
    ),
  getUserAuthentication: () =>
    LocalStorage.get(
      `${LocalStorage.get(authDataStorageKeys.appId)}${
        authDataStorageKeys.userAuthentication
      }`
    ),
  removeApiKeyAndUuid: () => {
    LocalStorage.remove(
      `${LocalStorage.get(authDataStorageKeys.appId)}${
        authDataStorageKeys.apiKey
      }`
    );
    LocalStorage.remove(
      `${LocalStorage.get(authDataStorageKeys.appId)}${
        authDataStorageKeys.uuid
      }`
    );
    LocalStorage.remove(
      `${LocalStorage.get(authDataStorageKeys.appId)}${
        authDataStorageKeys.userAuthentication
      }`
    );
    LocalStorage.remove(
      `${LocalStorage.get(authDataStorageKeys.appId)}:banner_bottom`
    );
    LocalStorage.remove(
      `${LocalStorage.get(authDataStorageKeys.appId)}:banner_top`
    );
  },
  storeLanguage: (lang) => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);

    if (appId && lang) {
      LocalStorage.set(`${appId}${authDataStorageKeys.lang}`, lang);
    }
  },
  getLang: () =>
    LocalStorage.get(
      `${LocalStorage.get(authDataStorageKeys.appId)}${
        authDataStorageKeys.lang
      }`
    ),

  getBannerBottom: () =>
    LocalStorage.get(
      `${LocalStorage.get(authDataStorageKeys.appId)}:banner_bottom`
    ),

  getBannerTop: () =>
    LocalStorage.get(
      `${LocalStorage.get(authDataStorageKeys.appId)}:banner_top`
    ),
};
