import routes from "../lib/ApiRoutes";
import { checkResponse, get, post } from "../lib/FetchHelper";

export default {
  getAppConfig(appIdentifier) {
    return get(routes.getAppConfig(appIdentifier), {}, true).then(
      checkResponse
    );
  },
  getAppFrontpage() {
    return get(routes.getAppFrontpage(), {}, true).then(checkResponse);
  },
  getAppWorkspaces() {
    return get(routes.getAppWorkspaces(), {}, true).then(checkResponse);
  },
  pushNotificationTokenRequest(token) {
    return post(
      routes.pushNotificationTokenRequest(),
      {
        token: token,
      },
      true
    ).then(checkResponse);
  },
};
