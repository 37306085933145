import createReducer from '../lib/utils/CreateReducer';
import UserConstants from '../constants/UserConstants';
import _unionBy from 'lodash/unionBy';
import { uploadDocument, uploadImage } from '../actions/UserActions';

const {
  GET_USER,
  FILTER_SEARCH,
  GET_USER_DETAIL,
  GET_MY_PROFILE_DETAIL,
  ENDORSE_USER,
  REMOVE_ENDORSE_USER,
  FAVOURITE_USER,
  REMOVE_FAVOURITE_USER,
  GET_FAVOURITE_USERS,
  GET_MATCH_RECOMMENDATION,
  GET_SAME_TOPIC_USERS,
  SORT_RESULT,
  SEARCH_TOPIC,
  SELECTED_SORT_OPTION,
  UPDATE_EDITED_USER_PROFILE,
  UPLOAD_PROFILE_IMAGE,
  UPDATE_PROFILE_FIELD_VALUE,
  UPDATE_PROFILE,
  UPLOAD_IMAGE,
  UPLOAD_DOCUMENT,
  GET_TOPIC,
  EMPTY_USER,
  EMPTY_IMAGE,
  TOGGLE_ONLINE,
  GET_TOPIC_DISCUSSION_THREAD,
} = UserConstants;

export const getInitialState = () => ({
  loading: false,
  errors: {},
  userList: {},
  filteredUserList: [],
  userDetail: {},
  myDetail: {},
  favouriteUserList: {},
  recommendationList: {},
  searchInput: '',
  sameTopicUserList: {},
  userListAfterSortResult: [],
  searchTopicInput: '',
  searchTopicList: [],
  selectedOption: {
    id: 2,
    optionName: 'A - Z',
    highlightIconArrowVisible: true,
  },
  myEditedProfileDetail: {},
  count: 0,
  topic: {},
  topicsCount: 0,
  discussion_thread: {},
  uploadedDocument: null,
  uploadedImage: null,
});

export default createReducer(getInitialState, {
  [`${GET_USER}_REQUEST`]: () => ({
    loading: true,
    errors: {},
  }),

  [`${GET_USER}_SUCCESS`]: (state, { payload: userList }) => ({
    userList,
    loading: false,
  }),

  GET_TOTAL_USERS_SUCCESS: (state, { payload: count }) => ({
    count,
  }),

  [`${GET_USER}_STOP_LOADING`]: () => ({
    loading: false,
  }),

  [`${GET_USER}_FAILURE`]: (state, { payload: errors }) => ({
    loading: false,
    errors,
  }),

  [`${FILTER_SEARCH}_REQUEST`]: (
    state,
    { payload: { searchInput } }
  ) => ({
    searchInput,
  }),

  [`${FILTER_SEARCH}_SUCCESS`]: (
    state,
    { payload: filteredUserList }
  ) => ({
    loading: false,
    filteredUserList,
  }),

  [`${FILTER_SEARCH}_FAILURE`]: (state, { payload: errors }) => ({
    loading: false,
    errors,
  }),

  [`${SORT_RESULT}_REQUEST`]: () => ({
    loading: true,
    errors: {},
  }),

  [`${EMPTY_USER}`]: (state, action) => ({
    loading: false,
    userListAfterSortResult: [],
    filteredUserList: [],
  }),

  [`${EMPTY_IMAGE}`]: (state, action) => ({
    uploadedImage: undefined,
  }),

  [`${SORT_RESULT}_SUCCESS`]: (
    state,
    { payload: userListAfterSortResult }
  ) => ({
    loading: false,
    userListAfterSortResult: _unionBy(
      state.userListAfterSortResult,
      userListAfterSortResult,
      'uuid'
    ),
  }),

  [`${SORT_RESULT}_FAILURE`]: (state, { payload: errors }) => ({
    loading: false,
    errors,
  }),

  [`${SELECTED_SORT_OPTION}_REQUEST`]: (
    state,
    { payload: { selectedOption } }
  ) => ({
    selectedOption,
  }),

  [`${GET_USER_DETAIL}_REQUEST`]: () => ({
    loading: true,
    errors: {},
  }),

  [`${GET_USER_DETAIL}_SUCCESS`]: (
    state,
    { payload: userDetail }
  ) => ({
    userDetail,
    loading: false,
  }),

  [`${GET_USER_DETAIL}_STOP_LOADING`]: () => ({
    loading: false,
  }),

  [`${GET_USER_DETAIL}_FAILURE`]: (state, { payload: errors }) => ({
    loading: false,
    errors,
  }),

  [`${GET_MY_PROFILE_DETAIL}_REQUEST`]: () => ({
    loading: false,
    myDetail: {},
  }),

  [`${GET_MY_PROFILE_DETAIL}_SUCCESS`]: (
    state,
    { payload: myDetail }
  ) => ({
    loading: false,
    myDetail,
  }),

  [`${GET_MY_PROFILE_DETAIL}_STOP_LOADING`]: () => ({
    loading: false,
  }),

  [`${GET_MY_PROFILE_DETAIL}_FAILURE`]: (
    state,
    { payload: errors }
  ) => ({
    loading: false,
    errors,
  }),

  [`${ENDORSE_USER}_REQUEST`]: () => ({
    errors: {},
  }),

  [`${ENDORSE_USER}_FAILURE`]: (state, { payload: { errors } }) => ({
    errors,
  }),

  [`${REMOVE_ENDORSE_USER}_REQUEST`]: () => ({
    errors: {},
  }),

  [`${REMOVE_ENDORSE_USER}_SUCCESS`]: () => ({}),

  [`${REMOVE_ENDORSE_USER}_FAILURE`]: (
    state,
    { payload: { errors } }
  ) => ({
    errors,
  }),

  [`${FAVOURITE_USER}_REQUEST`]: () => ({
    errors: {},
  }),

  [`${FAVOURITE_USER}_FAILURE`]: (
    state,
    { payload: { errors } }
  ) => ({
    errors,
  }),

  [`${REMOVE_FAVOURITE_USER}_REQUEST`]: () => ({
    errors: {},
  }),

  [`${REMOVE_FAVOURITE_USER}_SUCCESS`]: () => ({}),

  [`${REMOVE_FAVOURITE_USER}_FAILURE`]: (
    state,
    { payload: { errors } }
  ) => ({
    errors,
  }),

  [`${GET_FAVOURITE_USERS}_REQUEST`]: () => ({
    loading: true,
    errors: {},
  }),

  [`${GET_FAVOURITE_USERS}_SUCCESS`]: (
    state,
    { payload: favouriteUserList }
  ) => ({
    favouriteUserList,
  }),

  [`${GET_FAVOURITE_USERS}_STOP_LOADING`]: () => ({
    loading: false,
  }),

  [`${GET_FAVOURITE_USERS}_FAILURE`]: (
    state,
    { payload: errors }
  ) => ({
    loading: false,
    errors,
  }),

  [`${GET_MATCH_RECOMMENDATION}_REQUEST`]: () => ({
    loading: true,
    errors: {},
  }),

  [`${GET_MATCH_RECOMMENDATION}_SUCCESS`]: (
    state,
    { payload: recommendationList }
  ) => ({
    recommendationList,
  }),

  [`${GET_MATCH_RECOMMENDATION}_STOP_LOADING`]: () => ({
    loading: false,
  }),

  [`${GET_MATCH_RECOMMENDATION}_FAILURE`]: (
    state,
    { payload: errors }
  ) => ({
    loading: false,
    errors,
  }),

  [`${GET_SAME_TOPIC_USERS}_REQUEST`]: () => ({
    errors: {},
  }),
  [`${GET_TOPIC_DISCUSSION_THREAD}_REQUEST`]: () => ({
    errors: {},
    loading: true,
  }),

  [`${GET_SAME_TOPIC_USERS}_SUCCESS`]: (
    state,
    { payload: sameTopicUserList }
  ) => ({
    loading: false,
    sameTopicUserList,
  }),
  [`${GET_TOPIC_DISCUSSION_THREAD}_SUCCESS`]: (
    state,
    { payload }
  ) => ({
    loading: false,
    discussion_thread: payload,
  }),

  [`${GET_SAME_TOPIC_USERS}_FAILURE`]: (
    state,
    { payload: errors }
  ) => ({
    loading: false,
    errors,
  }),

  [`${SEARCH_TOPIC}_REQUEST`]: (
    state,
    { payload: { searchTopicInput } }
  ) => ({
    searchTopicInput,
  }),

  [`${SEARCH_TOPIC}_RESET`]: (state) => ({
    searchTopicList: [],
  }),

  [`${SEARCH_TOPIC}_SUCCESS`]: (state, { payload: data }) => ({
    searchTopicList: [...state.searchTopicList, ...data.data],
    topicsCount: data.headers['x-total-count'],
  }),

  [`${SEARCH_TOPIC}_FAILURE`]: (state, { payload: errors }) => ({
    errors,
  }),

  [`${GET_TOPIC}_SUCCESS`]: (state, { payload: topic }) => ({
    topic,
  }),

  [`${GET_TOPIC}_FAILURE`]: (state, { payload: errors }) => ({
    errors,
  }),

  [`${UPDATE_EDITED_USER_PROFILE}_REQUEST`]: () => ({
    errors: {},
  }),

  [`${UPDATE_EDITED_USER_PROFILE}_SUCCESS`]: (
    state,
    { payload: myEditedProfileDetail }
  ) => ({
    loading: false,
    myEditedProfileDetail,
  }),

  [`${UPDATE_EDITED_USER_PROFILE}_FAILURE`]: (
    state,
    { payload: errors }
  ) => ({
    loading: false,
    errors,
  }),

  [`${UPDATE_PROFILE_FIELD_VALUE}_REQUEST`]: () => ({
    errors: {},
  }),

  [`${UPDATE_PROFILE_FIELD_VALUE}_SUCCESS`]: (state) => ({
    loading: false,
  }),

  [`${UPDATE_PROFILE_FIELD_VALUE}_FAILURE`]: (
    state,
    { payload: errors }
  ) => ({
    loading: false,
    errors,
  }),

  [`${UPDATE_PROFILE}_REQUEST`]: () => ({
    errors: {},
  }),

  [`${UPDATE_PROFILE}_SUCCESS`]: (state) => ({
    loading: false,
  }),

  [`${UPDATE_PROFILE}_FAILURE`]: (state, { payload: errors }) => ({
    loading: false,
    errors,
  }),

  [`${UPLOAD_PROFILE_IMAGE}_REQUEST`]: () => ({
    errors: {},
  }),

  [`${UPLOAD_PROFILE_IMAGE}_SUCCESS`]: () => ({}),

  [`${UPLOAD_PROFILE_IMAGE}_FAILURE`]: (
    state,
    { payload: { errors } }
  ) => ({
    errors,
  }),

  [`${UPLOAD_DOCUMENT}_CANCEL`]: (state) => ({
    errors: {},
  }),

  [`${UPLOAD_DOCUMENT}_REQUEST`]: (state, { payload: document }) => ({
    errors: {},
  }),

  [`${UPLOAD_DOCUMENT}_SUCCESS`]: (
    state,
    { payload: uploadedDocument }
  ) => ({
    uploadedDocument: uploadedDocument,
  }),

  [`${UPLOAD_DOCUMENT}_FAILURE`]: (
    state,
    { payload: { errors } }
  ) => ({
    uploadedDocument: null,
    errors,
  }),

  [`${UPLOAD_IMAGE}_REQUEST`]: (state, { payload: image }) => ({
    errors: {},
  }),

  [`${UPLOAD_IMAGE}_SUCCESS`]: (
    state,
    { payload: uploadedImage }
  ) => ({
    uploadedImage: uploadedImage,
  }),

  [`${UPLOAD_IMAGE}_FAILURE`]: (state, { payload: { errors } }) => ({
    uploadedImage: null,
    errors,
  }),

  [`${TOGGLE_ONLINE}_FAILURE`]: (state, { payload: { errors } }) => ({
    errors,
  }),
});
